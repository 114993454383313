<template>
    <v-container class="my-10">
        <div class="d-flex">
            <v-btn
                icon
                color="accent darken-3"
                class="align-self-center"
                link
                :to="{ name: computedNextRoute }"
            >
                <v-icon class="mr-2"> fa-solid fa-chevron-left </v-icon>
            </v-btn>
            <h2 class="main-heading">
                {{ $t(`Admin.CreateEditNew.${heading}`) }}
            </h2>
        </div>
        <base-card class="flex-col-between">
            <template #content>
                <v-card-text>
                    <upload-input
                        :image-url.sync="formValues.image_url"
                        :current-image="item.image_url"
                        :validation.sync="validationErrors"
                        :in-valid.sync="isImageUrlInalid"
                    ></upload-input>
                    <text-input
                        :input-value.sync="formValues.title"
                        :current-value="item.title"
                        :validation.sync="validationErrors"
                        :in-valid.sync="isTitleInalid"
                        :label="$t(`Admin.CreateEditNew.form.title`)"
                    ></text-input>

                    <!-- <text-area
                            :input-value.sync="formValues.description"
                            :current-value="item.description"
                            :in-valid.sync="isDescriptionInalid"
                            :label="$t(`Admin.CreateEditNew.form.description`)"
                        ></text-area> -->
                    <text-editor
                        v-model="formValues.description"
                        :current-value="item.description"
                        :validation.sync="validationErrors"
                        :in-valid.sync="isDescriptionInalid"
                    ></text-editor>
                    <p 
                     class="mb-7 mt-1 text-end text-caption"
                    >
                     <span :class="`${descriptionLength > 20000 ? 'error--text' : ''}`">
                       {{ descriptionLength }}
                     </span>/
                     <span>20000</span>
                    </p>

                    <select-input
                        :input-value.sync="formValues.type"
                        :current-value="formValues.type"
                        :items="articleTypes"
                        :multiple="false"
                        :in-valid.sync="isTypeInvalid"
                        :label="$t(`Admin.CreateEditNew.form.type`)"
                    ></select-input>
                    <date-picker
                        v-model="formValues.date"
                        :in-valid.sync="isDateInvalid"
                        :label="$t(`Admin.CreateEditNew.form.date`)"
                    >    
                    </date-picker>
                </v-card-text>
                <v-divider class="my-5"></v-divider>
                <v-row class="align-center mr-4">
                    <v-col
                     cols="12"
                     md="6"
                     sm="12"
                     class="d-flex justify-center justify-md-start mt-5"
                    >
                     <ul v-if="validationErrors.length != 0">
                        <div class="d-flex">
                            <v-icon small left color="warning">
                                fa fa-warning
                            </v-icon>
                            <h3 class="main-heading">
                                Form Failed to Submit!
                            </h3>
                        </div>
                        <li
                         v-for="(error, index) in validationErrors"
                         :key="index"
                         style="list-style: none;"
                         class="error--text text-start"
                        >
                         {{ error.toString() }}
                        </li>
                     </ul>
                    </v-col>
                    <v-col
                     cols="12"
                     md="6"
                     sm="12"
                     class="d-flex justify-center justify-md-end mt-5"
                    >
                        <v-card-actions>
                            <v-row>
                                <v-col class="d-flex justify-end">
                                    <v-btn
                                        depressed
                                        color="primary"
                                        :disabled="disabled"
                                        :loading="
                                            hasParamsId()
                                                ? handleEditNewStatus_Pending
                                                : handleCreateNewStatus_Pending
                                        "
                                        @click="choseAction()"
                                    >
                                    <v-icon left small>fa-solid fa-save</v-icon>
                                        {{ actionMethodLabel }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-col>
                </v-row>
            </template>
        </base-card>
    </v-container>
</template>

<script>
import { apiStatus } from "@/api/constants/apiStatus";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import { withAsync } from "@/helpers/withAsync";
import {
    getNewsTypes,
    getSingleNew,
    createNew,
    updateNew,
} from "@/api/adminApi.js";

import TextInput from "@/components/Inputs/TextInput.vue";
import TextArea from "@/components/Inputs/TextArea.vue";
import UploadInput from "@/components/Inputs/UploadInput.vue";
import SelectInput from "@/components/Inputs/SelectInput.vue";
import TextEditor from "../../components/Inputs/TextEditor.vue";
import DatePicker from "../../components/Inputs/DatePicker.vue";
import { getSingleEvent } from '../../api/adminApi';

export default {
    name: "CreateEditSme",
    props: {
        id: {
            type: [String, Number],
            required: false,
            default: null,
        },
    },
    components: {
        TextInput,
        TextArea,
        UploadInput,
        SelectInput,
        TextEditor,
        DatePicker,
    },

    data() {
        return {
            item: {},

            formValues: {
                type: null,
                date: ''
            },
            descriptionLength: 0,
            validationErrors: [],
            articleTypes: [],
            isTitleInalid: this.hasParamsId() ? false : true,
            isDescriptionInalid: this.hasParamsId() ? false : true,
            isImageUrlInalid: this.hasParamsId() ? false : true,
            isTypeInvalid: this.hasParamsId() ? false : true,
            isDateInvalid: this.hasParamsId() ? false : true,

            handleCreateNewStatus: apiStatus.Idle,
            handleEditNewStatus: apiStatus.Idle,

            textEditorContent: "This is m description",
        };
    },

    computed: {
        ...apiStatusComputed(["handleCreateNewStatus", "handleEditNewStatus"]),
        heading() {
            if (this.hasParamsId()) {
                return "editHeading";
            }
            return "createHeading";
        },
        actionMethodLabel() {
            return this.hasParamsId()
                ? this.$t("Admin.CreateEditNew.actionButtonUpdate")
                : this.$t("Admin.CreateEditNew.actionButtonCreate");
        },
        //Form validation
        disabled() {
            if (this.hasParamsId()) {
                for (const key in this.formValues) {
                    if (this.formValues.hasOwnProperty(key)) {
                        return this.handleValidation();
                    }
                }
                return true;
            }
            return this.handleValidation();
        },

        computedNextRoute() {
            if (this.$route.query?.from === "events") return "Events";
            return "News";
        },
    },

    methods: {
        async handleFormParams() {
            const { response, error } = await withAsync(getNewsTypes);
            if (error) {
                return;
            }
            this.articleTypes = response.data.data;
            const from = this.$route.query?.from;

            if (!this.id) {
                if (from === "events") {
                    this.formValues.type = this.articleTypes[1].id;
                    this.isTypeInvalid = false;
                } else {
                    this.formValues.type = this.articleTypes[0].id;
                    this.isTypeInvalid = false;
                }
            } else {
                this.formValues.type = this.articleTypes.find(
                    (article) => article.name === this.item.type
                )?.id;
            }
        },
        async handleSingleNew() {

            let fn = getSingleNew;
            if(this.$route.query?.from === 'events') fn = getSingleEvent;

            const payload = {
                id: this.id,
            };

            const { response, error } = await withAsync(fn, payload);

            if (error) {
                return;
            }
            this.item = response.data.data;
            this.formValues.date = this.item.date;
            this.formValues.type = this.articleTypes.find(
                (article) => article.name === this.item.type
            )?.id;
        },

        async handleCreateNew() {
            this.handleCreateNewStatus = apiStatus.Pending;
            let data = new FormData();
            data.append("title", this.formValues.title);
            data.append("description", this.formValues.description);
            data.append("image_url", this.formValues.image_url);
            data.append("type", this.formValues.type);
            data.append("date", this.formValues.date);
            const { response, error } = await withAsync(createNew, data);

            if (error) {
                this.handleCreateNewStatus = apiStatus.Error;
                this.validationErrors = error.response.data.metadata.response_message;
                return;
            }
            this.handleCreateNewStatus = apiStatus.Success;
            this.$router.push({ name: this.computedNextRoute });
        },
        async handleEditNew() {
            this.handleEditNewStatus = apiStatus.Pending;
            let data = new FormData();
            for (const [key, value] of Object.entries(this.formValues)) {
                data.append([key], value);
            }

            const payload = {
                id: this.id,
                data: data,
            };

            const { response, error } = await withAsync(updateNew, payload);

            if (error) {
                this.handleEditNewStatus = apiStatus.Error;
                this.validationErrors = error.response.data.metadata.response_message;
                return;
            }
            this.handleEditNewStatus = apiStatus.Success;
            this.$router.push({ name: this.computedNextRoute });
        },

        choseAction() {
            this.hasParamsId() ? this.handleEditNew() : this.handleCreateNew();
        },

        handleValidation() {
            if (this.isTitleInalid) {
                return true;
            }
            if (this.isDescriptionInalid) {
                return true;
            }
            if (this.isImageUrlInalid) {
                return true;
            }
            if (this.isInnovationInalid) {
                return true;
            }
            if (this.isTypeInvalid) {
                return true;
            }
            if (this.isTechnologyInalid) {
                return true;
            }
            if (this.isLocationInalid) {
                return true;
            }
            if ( this.isDateInvalid) {
                return true;
            }
            return false;
        },

        hasParamsId() {
            return this.id ? true : false;
        },
    },

    watch: {
        isDescriptionInalid(newVal) {},
        
        "formValues.description": {
            handler(newVal) {
                if(newVal) {
                    this.descriptionLength = newVal.length;
                }
            },
            deep: true,
        }
    },

    created() {
        this.apiStatus = apiStatus;
        this.handleFormParams();
        if (this.id) {
            this.handleSingleNew();
        }
    },
};
</script>

<style lang="scss" scoped>
.form-divider {
    border-width: 1px;
}
</style>
